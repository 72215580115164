<template>
  <LargeLayout>
    <div class="container">
      <div class="row body">
        <div class="col-12 favourites-list p-0">
          <h1
            class="
              favourites-list__title
              text-uppercase text-center
              font-weight-bold
              text-success
            "
          >
            {{ $t("Favourites List") }}
          </h1>
          <div class="favourites-list__body">
            <div
              class="col-12"
              v-for="property in properties"
              :key="property._id"
            >
              <Card
                :property="property"
                :id="property._id"
                :title="property.title"
                :image="property.images[0]"
                :plan="property.plan"
                :address="property.address"
                :cost="property.cost"
                :utility_cost="property.utility_costs"
                :wifi_cost="property.wifi_cost"
                :area="property.square"
                :bedroom="property.bedroom"
                :bathroom="property.bathroom"
                :neighborhood="property.condition[0]"
              />
            </div>
          </div>

          <b-pagination
            v-if="properties.length > 0"
            v-model="page"
            :total-rows="count"
            :per-page="size"
            @click="handlePageChange"
            aria-controls="history-list"
            align="center"
          ></b-pagination>
        </div>
      </div>
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import Card from "../../components/utils/Card.vue";
import axiosConfig from "../../libs/axiosConfig";

export default {
  name: "favourites-list",
  components: {
    LargeLayout,
    Card,
  },
  data() {
    return {
      properties: [],
      size: 8,
      page: 1,
      count: 0,
    };
  },
  created() {
    axiosConfig
      .get("/user/favourite?page=" + this.page + "&size="+this.size)
      .then((response) => {
        this.properties = response.data.properties;
        this.count = response.data.totalItems;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      axiosConfig
        .get("/user/favourite?page=" + this.page + "&size="+this.size)
        .then((response) => {
          this.properties = response.data.properties;
          this.count = response.data.totalItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  // computed: {
  //   rows() {
  //     return this.favouritesList.length;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.favourites-list {
  .favourites-list__title {
    padding: 20px;
  }
  .favourites-list__body {
    .card {
      margin-bottom: 20px;
      .card-body {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        .col-4 {
          margin-bottom: 1rem;
        }
        .property {
          display: flex;
          .property-col {
            margin: 0;
            .property__paragraph {
              display: flex;
              .col-6 {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
